import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import SearchListItem from "../components/SearchListItem/SearchListItem";
import SEO from "../components/seo";
import { Heading5 } from "../components/Typography/Typography";
import { mutateKommuneList } from "../lib/helpers";

const AllKommunes = ({ location }) => {
  const { allSanityGroup, allSanityKommune } = useStaticQuery(ALL_KOMMUNES);
  const kommuner = mutateKommuneList(
    allSanityKommune.edges,
    allSanityGroup.edges
  ).filter((kommune) => kommune.groupUrl);

  return (
    <Layout location={location} standardPadding maxWidth>
      <SEO title="Alle FLYT-kommuner" />
      <Heading5>Alle FLYT-kommuner</Heading5>
      <ul style={{ padding: 0 }}>
        {kommuner
          .filter((kommune) => kommune.name)
          .sort((kommuneA, kommuneB) =>
            kommuneA.name.localeCompare(kommuneB.name)
          )
          .map((kommune, index) => (
            <SearchListItem kommune={kommune} key={`kommune-${index}`} />
          ))}
      </ul>
    </Layout>
  );
};

const ALL_KOMMUNES = graphql`
  query {
    allSanityGroup {
      edges {
        node {
          year
          kommune {
            _id
            name
            city
          }
        }
      }
    }
    allSanityKommune {
      edges {
        node {
          _id
          name
          city
        }
      }
    }
  }
`;

export default AllKommunes;
